<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <circle cx="16" cy="16" fill="inherit" r="15"/>
    <path d="M25 11.0001L13 23.0001L7.5 17.5001L8.91421 16.0859L13 20.1717L23.5858 9.58594L25 11.0001Z" fill="white"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-check-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 32 32'
    }
  }
}
</script>
